const VIEW_PROGRESS_BODY =
  "Didn’t finish a lab? Come back and continue where you left off through your account profile. All of your progress will be saved as you complete each lab.";
const VIEW_PROGRESS_TITLE = "View Progress";
const EXPLORE_LABS_BODY =
  "Ready to start learning? Access any of the labs below to learn more about a range of topics from accessibility to sound and speech, color blindness and even labs about algorithmic bias and more.";
const EXPLORE_LABS_TITLE = "Explore Our Labs";
const OUR_MISSION_BODY =
  "Accessible Learning Labs is an NSF funded initiative aimed at fostering STEM proficiency.";
const OUR_MISSION_TITLE = "Our Mission";
const ABOUT_US_BODY =
  "Find out more about the team that makes Accessible Learning Labs possible. We're proud of our group of principal investigators and advisors, as well as our undergraduate development team!";
const ABOUT_US_TITLE = "About Us";
const WELCOME_TO_ALL_TITLE = "Welcome to Accessible Learning Labs";
const WELCOME_TO_ALL_BODY =
  "Accessible Learning Labs is an NSF funded initiative aimed at fostering STEM proficiency. We are dedicated to equipping users with the skills and knowledge to create software solutions. ";
const ARE_YOU_AN_EDUCATOR_TITLE = "Are You an Educator?";
const ARE_YOU_AN_EDUCATOR_BODY =
  "Access the ability to create groups for your students, track their lab progress, date of completion, and quiz grade. Find more information and resources under our Educator Resources page.";

module.exports = {
  VIEW_PROGRESS_BODY,
  VIEW_PROGRESS_TITLE,
  EXPLORE_LABS_BODY,
  EXPLORE_LABS_TITLE,
  OUR_MISSION_TITLE,
  OUR_MISSION_BODY,
  ABOUT_US_BODY,
  ABOUT_US_TITLE,
  WELCOME_TO_ALL_BODY,
  WELCOME_TO_ALL_TITLE,
  ARE_YOU_AN_EDUCATOR_BODY,
  ARE_YOU_AN_EDUCATOR_TITLE,
};
