const ACCESSIBILITY = "ACCESSIBILITY";
const ALL_LABS = "ALL_LABS";
const AI_MACHINE_LEARNING = "AI_MACHINE_LEARNING";
const DIFFICULTY_1 = "DIFF_1";
const DIFFICULTY_2 = "DIFF_2";
const DIFFICULTY_3 = "DIFF_3";

module.exports = {
  ACCESSIBILITY,
  ALL_LABS,
  AI_MACHINE_LEARNING,
  DIFFICULTY_1,
  DIFFICULTY_2,
  DIFFICULTY_3,
};
